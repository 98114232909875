import PropTypes from "prop-types";

import { usePoints, useUserTier, useAuthenticateLL } from "../hooks/useALRewards";
import { useAuth } from "../context/AuthProvider";

import Header from "./alRewardsHeader/Header";

function ALRewardsHeader({ type = "" }) {
  const { accountDetails } = useAuth();
  useAuthenticateLL(accountDetails?.email);

  const [points, approvedPointsRef] = usePoints();
  const contentUpdateTicker = points;
  const [tier, nextTier, tierWidgetRef] = useUserTier(contentUpdateTicker);

  return (
    <div>
      <span data-lion-points="approved" ref={approvedPointsRef} style={{ display: "none" }} />
      <div data-lion-tier-overview ref={tierWidgetRef} style={{ display: "none" }} />
      <Header tier={tier} points={points} nextTier={nextTier} type={type} />
    </div>
  );
}

ALRewardsHeader.propTypes = {
  type: PropTypes.string,
};

export default ALRewardsHeader;
